<template>
  <a-modal :visible="visible" @cancel="closeModal" :footer="null" class="add-contributors-modal" :title="null">
    <div class="add-contributors-modal-container">
      <div class="add-contributors-modal-container__header">
        <h2 class="add-contributors-modal-container__header--title">
          Add Contributors
        </h2>
      </div>
      <div class="add-contributors-modal-container__body">
        <div class="add-contributors-modal-container__body--search-bar">
          <i class="icon icon-search"></i>
          <input v-model="searchQuery" class="search-input" type="text" placeholder="Search with name or email" />
        </div>
        <div class="add-contributors-modal-container__body--skeleton" v-if="loadingContributors">
          <skeleton-loader
            v-for="index in 4"
            :key="index"
          ></skeleton-loader>
        </div>
        <div v-else-if="filteredContributors.length === 0" class="add-contributors-modal-container__body--message">
          No contributors found.
        </div>
        <div v-else class="add-contributors-modal-container__body--contributors">
          <div class="contributor" v-for="contributor in filteredContributors" :key="contributor.id">
            <a-checkbox :checked="isChecked(contributor.id)" @change="toggleContributor(contributor.id)" />
            <div class="contributor__image">
              <img :src="contributor.profile_picture" alt="Image" />
            </div>
            <div class="contributor__name">
              <h1>{{ contributor.full_name }}</h1>
              <p>{{ contributor.email }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="add-contributors-modal-container__footer">
        <a-button :class="{ 'disable-save-btn': checkedContributors.length === 0 }" @click="addContributors"
          :loading="isLoading">Add</a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import skeletonLoader from "../BaseComponents/ConnectionCardSkeleton.vue"
export default {
  data() {
    return {
      isLoading: false,
      loadingContributors:false,
      searchQuery: "",
      checkedContributors: [],
    };
  },
  components: {
    skeletonLoader
  },
  props: {
    visible: Boolean,
  },
  computed: {
    allContributors() {
      return this.$store.getters["feed/getAllContributors"];
    },
    userId() {
      return this.$store.getters["profile/currentLoginUser"];
    },
    filteredContributors() {
      if (!this.searchQuery) {
        return this.allContributors;
      }
      const query = this.searchQuery.toLowerCase();
      return this.allContributors.filter(contributor =>
        contributor.full_name.toLowerCase().includes(query) ||
        contributor.email.toLowerCase().includes(query)
      );
    }
  },
  mounted() {
     this.getAllContributors();
  },
  methods: {
    async addContributors() {
      try {
        this.isLoading = true;
        const payload = {
          add_followers: this.checkedContributors,
          remove_followers: []
        }
        const res = await this.$store.dispatch("feed/addContributor", payload);
        if (res.status===200) {
          await this.getFollowes();
          this.closeModal()
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false
      }
      console.clear()
      console.log('checkedContributors', this.checkedContributors);
    },
    async getFollowes() {
      const payload = {
        id: this.userId,
        relation: "followers",
      };
      await this.$store.dispatch("feed/getFollowed", payload);
    },
    closeModal() {
      this.$emit("closeFeedUploadModal", false);
    },
    isChecked(id) {
      return this.checkedContributors.includes(id);
    },
    toggleContributor(id) {
      const index = this.checkedContributors.indexOf(id);
      if (index > -1) {
        this.checkedContributors.splice(index, 1);
      } else {
        this.checkedContributors.push(id);
      }

    },
    async getAllContributors() {
      try {
        this.loadingContributors = true;
        const payload = {
          id: this.userId,
          relation:'not_followers'
        };
        await this.$store.dispatch("feed/getFollowed", payload);
      } catch (err) {
        console.log(err);
      } finally {
        this.loadingContributors = false;
      }
    },
  },
};
</script>

<style lang="scss">
.add-contributors-modal {
  width: 50rem !important;
  top: 5rem !important;

  .ant-modal-content {
    box-shadow: 0px 0px 94px #00000047;

    .ant-modal-close {
      margin: 2.4rem 2rem 0 0;

      .ant-modal-close-x {
        padding: 0.5rem;
        width: fit-content;
        height: fit-content;
        line-height: normal;

        .anticon {
          color: $color-black;
          font-size: 2rem;
        }
      }
    }

    .ant-modal-body {
      .add-contributors-modal-container {
        border-radius: 1rem;

        &__header {
          padding: 2.5rem;

          &--title {
            font-size: 2.4rem;
            font-family: $font-primary-bold;
            line-height: normal;
            margin-bottom: 0;
            color: $color-black;
          }
        }

        &__body {
          &--search-bar {
            width: calc(100% - 5rem);
            margin: 0 2.5rem 2rem;
            display: flex;
            align-items: center;
            background-color: #ececec;
            padding: 0 1.7rem;
            border-radius: 100px;

            .icon {
              font-size: 1.4rem;
              opacity: 0.6;
              color: $color-black;
            }

            .search-input {
              font-size: 1.6rem;
              font-family: $font-primary;
              line-height: normal;
              padding: 1rem 1.2rem;
              border: none;
              outline: none;
              width: 100%;
              background-color: transparent;

              &::placeholder {
                color: $color-black;
                opacity: 0.4;
              }
            }
          }
          &--message {
            font-size: 1.6rem;
            font-family: $font-primary;
            line-height: 2.2rem;
            margin-bottom: 0;
            color: $color-black;
            padding: 0 2rem;
            margin: 4rem 0;
            text-align: center;
          }
          &--contributors {
            overflow-y: auto;
            max-height: 45vh;

            .contributor {
              display: flex;
              align-items: center;
              padding: 1.5rem 2.6rem;
              border-bottom: 1px solid $color-dark-grey-5;

              &__image {
                width: 4rem;
                height: 4rem;
                border-radius: 100%;

                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  border-radius: 100%;
                  background-color: #ececec;
                }
              }

              &__name {
                margin-left: 1rem;

                h1 {
                  font-family: $font-primary-medium;
                  font-size: 1.6rem;
                  color: $color-black;
                  line-height: 2rem;
                  margin-bottom: 0;
                }

                p {
                  font-family: $font-primary;
                  font-size: 1.4rem;
                  color: $color-black;
                  line-height: 1.8rem;
                  margin-bottom: 0;
                  opacity: 0.6;
                }
              }

              .ant-checkbox-wrapper {
                margin-left: 0;
                font-size: 1.6rem;
                font-family: $font-secondary;
                width: fit-content;
                margin-bottom: 0;
                line-height: 2rem;
                margin-right: 1.8rem;

                .ant-checkbox {
                  .ant-checkbox-inner {
                    border: 0.18rem solid rgba(128, 128, 128, 0.377);
                    height: 18px;
                    width: 18px;
                    border-radius: 0;
                  }

                  .ant-checkbox-input {
                    width: fit-content;
                  }
                }
              }

              .ant-checkbox-wrapper-checked {
                .ant-checkbox-checked {
                  .ant-checkbox-inner {
                    background-color: $color-primary;
                    border-color: $color-primary;
                    height: 18px;
                    width: 18px;
                    border-radius: 0;
                  }
                }
              }
            }

            &::-webkit-scrollbar-track {
              background-color: transparent;
            }

            &::-webkit-scrollbar {
              width: 0.4rem;
              height: 0.4rem;
              background-color: transparent;
              border-radius: 3rem;
            }

            &::-webkit-scrollbar-thumb {
              background-color: $color-primary;
              border-radius: 3rem;
            }
          }
          &--skeleton {
            padding: 1.5rem 2.5rem 0;
            .connection-card-skeleton {
              margin: 0 0 2rem !important;
              .ant-skeleton-header {
                .ant-skeleton-avatar {
                  border-radius: 100%;
                }
              } 
            }
          }
        }

        &__footer {
          padding: 2.5rem 2.5rem;
          border-top: 1px solid $color-dark-grey-5;
          margin-top: -1px;

          .ant-btn {
            width: 10rem;
            background-color: $color-primary;
            border: none;
            outline: none;
            height: 4rem;
            font-size: 1.4rem;
            font-family: $font-primary-medium !important;
            color: $color-white;
            margin-left: auto;
            display: block;

            span {
              line-height: normal;
            }

            .anticon {
              .anticon-spin {
                font-size: 1.8rem;
                color: $color-primary;
              }
            }

            &:hover,
            &:focus {
              background-color: $color-primary;
              color: $color-white;
            }
          }

          .disable-save-btn {
            pointer-events: none;
            opacity: 0.5;
          }
        }
      }
    }
  }
}
</style>
